import React, { useState } from 'react';
import MainLayout from '../layouts/main';
import {
  ButtonBase,
  Dialog,
  DialogContent,
  Typography
} from '@material-ui/core';
import styled from '../styled';
import {
  AffiliateDashboardSection,
  RevenueAttributionSection,
  HeatmapSection,
  RevisionsSection,
  SegmentationSection,
  Label
} from '../components/Section';
import { SEO } from '../components/SEO';
import {
  SignupButton,
  SignupButtonWithChoice
} from '../components/SignupButton';
import { SalesCallout } from '../components/SalesCallout';
import { DemoButton } from '../components/DemoButton';
import {
  FeatureAffiliateDashboard,
  FeatureCardGrid,
  FeatureContentAnalytics,
  FeatureConversionHeatmaps,
  FeatureLinkGenerator,
  FeatureRevisionTracking,
  FeatureSegmentation
} from '../components/FeatureGrid';
import { MoreFeatures } from '../components/MoreFeatures';
import EventList from '../components/EventList';
import { GetStartedDialog } from '../components/GetStartedDialog';
import { CtaButton } from '../components/CtaButton';
import { ArrowRightCircle } from 'react-feather/dist';
import { COLORS } from '../themes/color';
import { VIDEOS, VimeoVideo } from '../components/VimeoVideo';

export const DoubleTestimonial = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: ${(p) => p.theme.spacing(2)}px auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

type SelectedVideo = {
  videoId: string;
  title: string;
  description: string;
  image: string;
};

const VideoArea = () => {
  const videos: SelectedVideo[] = [
    {
      videoId: VIDEOS.REVISION,
      image: '/images/videos/revisions-video-screenshot.png',
      title: 'Revision tracking',
      description:
        'Automatically capture changes to your content, so you can see how edits impact your main KPIs. Then annotate those revisions with notes.'
    },
    {
      videoId: VIDEOS.HEATMAPS,
      title: 'Affiliate Heatmaps',
      image: '/images/videos/heatmaps-video-screenshot.png',
      description:
        'Capture affiliate performance for each link placement within an article. Visualize your data in an intuitive way, so your whole team can action it.'
    },
    {
      videoId: VIDEOS.LINK_GENERATOR,
      title: 'Link generator tool',
      image: '/images/videos/link-generator-screenshot.png',
      description:
        'Generate all your affiliate links from within a single tool. Simply paste your deep link and go. Also available through an API.'
    }
  ];
  const [selectedVideo, setSelectedVideo] = useState<SelectedVideo | null>(
    null
  );

  return (
    <>
      <VideoGrid>
        {videos.map((video) => (
          <div key={video.videoId}>
            <ButtonBase
              onClick={() => {
                setSelectedVideo(video);
                if (typeof window !== 'undefined') {
                  (window as any).plausible('Video click', {
                    props: { title: video.title }
                  });
                }
              }}
            >
              <img src={video.image} alt={video.title} />
            </ButtonBase>
            <Label>{video.title}</Label>
            <Typography variant="body2" color="textSecondary" paragraph>
              {video.description}
            </Typography>
          </div>
        ))}
      </VideoGrid>
      <Dialog
        open={selectedVideo !== null}
        onClose={() => setSelectedVideo(null)}
        maxWidth="lg"
        scroll="body"
      >
        <DialogContent style={{ width: '100%' }}>
          {selectedVideo && (
            <div style={{ textAlign: 'center' }}>
              <VimeoVideo videoId={selectedVideo.videoId} />
              <br />
              <Label>{selectedVideo.title}</Label>
              <Typography variant="body2" color="textSecondary" paragraph>
                {selectedVideo.description}
              </Typography>
              <SignupButtonWithChoice text="Get a demo" variant="contained" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const SectionWithDescription: React.FC<{
  title?: React.ReactNode;
  description: React.ReactNode;
}> = ({ title, description }) => (
  <SectionArea>
    {title && (
      <Typography
        component="h2"
        variant="h3"
        gutterBottom
        style={{ fontWeight: 900 }}
      >
        {title}
      </Typography>
    )}
    <Typography
      paragraph
      color="textSecondary"
      variant="body1"
      style={{
        margin: '24px auto 48px'
      }}
    >
      {description}
    </Typography>
  </SectionArea>
);

const SectionArea = styled('div')`
  text-align: center;
  max-width: 750px;
  margin: 69px auto 0;
`;

const VideoGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    margin: 0 ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    margin: 0 ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }

  & > div {
    background-color: ${COLORS.PAPER};
    border-radius: ${(p) => p.theme.custom.borderRadius.unit * 3}px;
    padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(2)}px;

    img {
      display: block;
      position: relative;
      transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out,
        filter 0.15s ease-in-out;
      filter: grayscale(0.4);
      opacity: 0.8;
      max-width: 100%;
      margin-bottom: ${(p) => p.theme.spacing(4)}px;
      border-radius: ${(p) => p.theme.custom.borderRadius.unit * 1.5}px;

      &:hover {
        transform: scale(1.03);
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
`;

const CustomerLogos = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 12px auto;
  flex-wrap: wrap;

  img {
    flex-shrink: 0;
    height: 32px;
    opacity: 0.6;
    float: none;
    margin: 0;
  }

  @media (max-width: 800px) {
    justify-content: center;

    img {
      height: 24px;
      margin: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

const LogoBar = styled('div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing(10)}px 12px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(4)}px;
  }

  span {
    color: ${COLORS.TEXT};
    opacity: 0.7;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 13px;
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
    width: 485px;
    text-align: left !important;
  }

  hr {
    display: inline-block;
    width: 100%;
    border: 1px solid ${COLORS.TEXT};
    opacity: 0.2;
    height: 1px;
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  .logo-container {
    --gap: 1rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    padding: ${(p) => p.theme.spacing(1)}px 0 ${(p) => p.theme.spacing(3)}px;
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
    height: 55px;

    .logo-content {
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;
      gap: var(--gap);
      min-width: 100%;
      animation: marquee 60s linear infinite;
    }

    img {
      flex-shrink: 0;
      height: 24px;
      opacity: 0.5;
      float: none;
      margin-right: ${(p) => p.theme.spacing(10)}px;
    }
  }
`;

const Logos = () => (
  <>
    <img
      src="/images/logos/amazon-logo.svg"
      alt="Amazon"
      style={{ position: 'relative', top: '6px' }}
    />
    <img src="/images/logos/awin-logo.svg" alt="Awin" />
    <img src="/images/logos/impact-logo.svg" alt="Impact" />
    <img
      src="/images/logos/skimlinks-logo.svg"
      alt="Skimlinks"
      style={{ height: '16px', position: 'relative', top: '6px' }}
    />
    <img src="/images/logos/cj-affiliate-logo.svg" alt="CJ Affiliate" />
    <img
      src="/images/logos/partnerize-logo.svg"
      alt="Partnerize"
      style={{ height: '16px', position: 'relative', top: '3px' }}
    />
    <img src="/images/logos/rakuten-logo.svg" alt="Rakuten" />
    <img
      src="/images/logos/tune-logo.svg"
      alt="TUNE"
      style={{ height: '16px', position: 'relative', top: '6px' }}
    />
    <img src="/images/logos/refersion-logo.svg" alt="Refersion" />
    <img src="/images/logos/bookingcom-logo.svg" alt="Booking.com" />
  </>
);

const HeroSvg = () => (
  <div style={{ position: 'absolute', bottom: 210, zIndex: 0 }}>
    <svg
      width="1440"
      height="430"
      viewBox="0 0 1440 430"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-52 288.041C43.3963 288.041 48.5675 366.216 143.964 366.216C239.36 366.216 239.36 222.547 334.756 222.547C430.153 222.547 430.153 306.201 525.549 306.201C620.945 306.201 620.945 178.962 716.341 178.962C811.737 178.962 811.738 3.45825 907.134 3.45825C1002.53 3.45825 1002.53 184.704 1097.93 184.704C1193.32 184.704 1181.93 100.348 1264.11 100.348C1346.28 100.348 1351.32 319.799 1446.72 319.799C1446.95 319.799 1447.21 430 1446.72 430C1434.98 430 -52 430 -52 430V288.041Z"
          fill="url(#paint0_linear_11382_12585)"
        />
        <path
          d="M-51.6016 288.155C44.2159 288.155 44.2159 366.762 140.033 366.762C235.851 366.762 235.851 222.299 331.668 222.299C427.486 222.299 427.486 306.415 523.303 306.415C619.121 306.415 619.121 178.473 714.938 178.473C810.756 178.473 810.756 2 906.573 2C1002.39 2 1002.39 184.247 1098.21 184.247C1194.03 184.247 1180.06 98.5731 1259.91 98.5731C1339.76 98.5731 1347.18 320.123 1443 320.123"
          stroke="#FFDBA1"
          strokeOpacity="0.37"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11382_12585"
          x1="-131.753"
          y1="-49.8597"
          x2="-131.753"
          y2="430"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.339495" stopColor="#FFDBA1" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

const MiniTestimonial = styled('div')`
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-gap: ${(p) => p.theme.spacing(0.5)}px;
  align-items: center;
  max-width: 550px;
  padding: ${(p) => p.theme.spacing(1.5)}px;

  img {
    border-radius: 100%;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    color: #888;

    strong {
      color: #555;
    }
  }
`;

const Hero = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  align-items: center;
  background-color: ${COLORS.PAPER};
  padding: ${(p) => p.theme.spacing(10)}px 0 ${(p) => p.theme.spacing(14)}px;
  color: ${COLORS.TEXT};
  margin-top: 36px;
  position: relative;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: ${(p) => p.theme.spacing(2)}px 0 ${(p) => p.theme.spacing(20)}px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    text-align: center;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }

  @media (max-width: 730px) {
    padding: ${(p) => p.theme.spacing(2)}px 0 ${(p) => p.theme.spacing(40)}px;
  }

  p {
    font-size: 18px;
    line-height: 32px;
    color: ${COLORS.TEXT};
    max-width: 550px;

    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
`;

const HeroImg = styled('img')`
  display: inline;
  width: 100%;
  box-shadow: 0px 0px 26px rgba(151, 141, 124, 0.2);
  border-radius: 8px;
  max-width: 600px;
  z-index: 1;
  position: relative;

  @media (max-width: 700px) {
    padding-left: ${(p) => p.theme.spacing(2)}px;
  }
`;

const HeroContent = styled('div')`
  margin-left: ${(p) => p.theme.spacing(10)}px;
  z-index: 1;

  h1 {
    font-weight: 900;
    font-size: 64px;
    line-height: 72px;
    color: #000;
  }

  @media (max-width: 1300px) {
    h1 {
      font-weight: 900;
      font-size: 56px;
      line-height: 64px;
    }
  }

  @media (max-width: 1000px) {
    h1 {
      font-weight: 900;
      font-size: 48px;
      line-height: 56px;
    }
  }

  @media (max-width: 900px) {
    h1 {
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
    }
  }

  @media (max-width: 700px) {
    margin-left: ${(p) => p.theme.spacing(1)}px;
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;

const Strong = styled('strong')`
  color: #000;
  box-shadow: inset 0 -12px 0 #f4ad0f29;
`;

const DoubleSection = styled('div')`
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  align-items: center;

  @media (max-width: 700px) {
    margin: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const Section = styled('div')`
  background-color: ${COLORS.PAPER};
  padding: ${(p) => p.theme.spacing(4)}px;
  border-radius: 8px;
`;

export default () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <MainLayout>
      <div>
        <SEO />
        <Hero>
          <HeroContent>
            <h1>
              Unlock your site's hidden affiliate revenue with content insights
              for publishers
            </h1>
            <p style={{ marginBottom: '24px' }}>
              Affilimate helps you{' '}
              <Strong>generate 2&ndash;10x revenue growth per article</Strong>{' '}
              with optimization tools &amp; content insights tailored for
              commerce content.
            </p>
            <CtaButton onClick={() => setDialogOpen(true)}>
              Get started today
              <ArrowRightCircle
                size={17}
                style={{ position: 'relative', top: '1px', right: '-8px' }}
              />
            </CtaButton>
            <br />
            <br />
          </HeroContent>
          <div style={{ textAlign: 'right' }}>
            <HeroImg src="/images/content-insights-hero.png" />
          </div>
          <HeroSvg />
          <LogoBar>
            <div style={{ display: 'flex' }}>
              <span>Trusted by innovative publishers like</span>
              <hr />
            </div>
            <CustomerLogos>
              <img src="/images/logos/wag.svg" />
              <img src="/images/logos/network-n.svg" />
              <img src="/images/logos/purewow.svg" />
              <img src="/images/logos/business-insider.svg" />
              <img src="/images/logos/a360-media.svg" />
              <img src="/images/logos/verticalscope.svg" />
              <img src="/images/logos/katie-couric-media.svg" />
            </CustomerLogos>
          </LogoBar>
        </Hero>
        <SectionWithDescription description="Affilimate gives publishers the ability to truly maximize in-page affiliate performance. Add your networks, track your links, and grow your revenue from existing commerce content." />
        <DoubleSection>
          <Section>
            <Typography variant="h6" component="p" paragraph>
              <strong>Media companies</strong>
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
              paragraph
            >
              Drive incremental revenue from existing content with in-page
              optimization tools and insights, designed specifically for
              business and editorial teams.
            </Typography>
            <DemoButton text="Get a demo" variant="contained" />
          </Section>
          <Section>
            <Typography variant="h6" component="p" paragraph>
              <strong>Professional creators</strong>
            </Typography>
            <Typography
              variant="body2"
              component="p"
              color="textSecondary"
              paragraph
            >
              Get the content insights and optimization tools every professional
              creator needs to unlock fast and systematic affiliate growth.
            </Typography>
            <SignupButton variant="outlined" />
          </Section>
        </DoubleSection>
        <br />
        <br />
        <br />
        <SectionWithDescription
          title="Tap in to 2&ndash;10x revenue growth per article"
          description={
            <>
              Affilimate gives you link-level visibility into your on-page
              performance, so you can discover lightweight improvements that
              multiply your affiliate RPM, EPC, and revenue.
            </>
          }
        />
        <HeatmapSection orientation="right" />
        <SegmentationSection orientation="right" />
        <RevisionsSection orientation="right" />
        <RevenueAttributionSection orientation="right" />
        <AffiliateDashboardSection orientation="right" />
        <div
          style={{
            textAlign: 'center',
            marginTop: '128px',
            marginBottom: '128px'
          }}
        >
          <SalesCallout />
        </div>
      </div>
      <SectionWithDescription
        title="Connect with us"
        description="Meet the team at an upcoming event."
      />
      <EventList />
      <GetStartedDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </MainLayout>
  );
};
